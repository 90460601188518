import BpkBadge, {
  BADGE_TYPES,
} from '@skyscanner/backpack-web/bpk-component-badge';
import BpkBreakpoint, {
  BREAKPOINTS,
} from '@skyscanner/backpack-web/bpk-component-breakpoint';
import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import AccountIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/account';
import BaggageIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/baggage-generic';
import BpkImage, {
  withLazyLoading,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkPrice, {
  SIZES,
  ALIGNS,
} from '@skyscanner/backpack-web/bpk-component-price';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type {
  ThemeCardProps,
  Badge,
} from '@skyscanner-internal/falcon-shared-types/types/ThemeCardProps';

import STYLES from './ThemeCard.module.scss';

const documentIfExists = typeof window !== 'undefined' ? document : null;
const LazyLoadedImage = withLazyLoading(BpkImage, documentIfExists);
const IMG_WIDTH = 392;
const IMG_HEIGHT = 184;

const IMG_WIDTH_MOBILE = 312;
const IMG_HEIGHT_MOBILE = 184;
const Icons: { [key: string]: JSX.Element } = {
  account: <AccountIcon />,
  baggage: <BaggageIcon />,
};
type ThemeCardImageProps = Pick<
  ThemeCardProps,
  'imageAltText' | 'imageSrc' | 'isMobileForSSR' | 'imageConfig'
>;

const ThemeCardImage = ({
  imageAltText,
  imageConfig,
  imageSrc,
  isMobileForSSR,
}: ThemeCardImageProps) => {
  const imgSrc = isMobileForSSR
    ? imageSrc.replace('_WxH', `_${IMG_WIDTH_MOBILE}x${IMG_HEIGHT_MOBILE}`)
    : imageSrc.replace('_WxH', `_${IMG_WIDTH}x${IMG_HEIGHT}`);

  const aspectRatio = isMobileForSSR
    ? IMG_WIDTH_MOBILE / IMG_HEIGHT_MOBILE
    : IMG_WIDTH / IMG_HEIGHT;
  switch (imageConfig?.layout) {
    case 'center':
      return (
        <div className={STYLES.themeCardImageCenterWrapper}>
          <div className={STYLES.themeCardImageCenter}>
            <LazyLoadedImage
              aspectRatio={imageConfig.aspectRatio}
              altText={imageAltText}
              src={imageSrc}
              aria-hidden
            />
          </div>
        </div>
      );
    case 'cover':
    default:
      return (
        <div className={STYLES.ThemeCard__themeCardImage}>
          <LazyLoadedImage
            aspectRatio={aspectRatio}
            altText={imageAltText}
            src={imgSrc}
            aria-hidden
          />
        </div>
      );
  }
};
const Badges = ({ badges }: { badges: Badge[] }) => (
  <div className={STYLES.ThemeCard__badges}>
    {badges.map((badge) => (
      <BpkBadge
        key={badge.title}
        type={BADGE_TYPES.normal}
        aria-label={badge.ariaLabel}
        role="status"
      >
        {badge?.icon && Icons[badge.icon] ? (
          <span className={STYLES.badgeIcon}>{Icons[badge.icon]}</span>
        ) : null}
        {badge.title}
      </BpkBadge>
    ))}
  </div>
);
const ThemeCard = ({
  badges = undefined,
  imageAltText,
  imageConfig = undefined,
  imageSrc,
  isMobileForSSR,
  isNeverHasPrice,
  leadingText,
  noPriceString,
  onClick,
  price,
  redirectUrl,
  rel = undefined,
  subtitle = undefined,
  target = undefined,
  textBody = undefined,
  title,
  topBadges = undefined,
  trailingText = undefined,
}: ThemeCardProps) => (
  <div className={STYLES.ThemeCardWrapper}>
    <BpkCard
      padded={false}
      className={STYLES.ThemeCard}
      href={redirectUrl}
      rel={rel}
      blank={target === '_blank'}
      onClick={onClick}
    >
      {topBadges && (
        <div className={STYLES.ThemeCard__topBadges}>
          <Badges badges={topBadges} />
        </div>
      )}
      <BpkBreakpoint query={BREAKPOINTS.MOBILE} matchSSR={isMobileForSSR}>
        <ThemeCardImage
          imageSrc={imageSrc}
          imageAltText={imageAltText}
          imageConfig={imageConfig}
          isMobileForSSR={isMobileForSSR}
        />
      </BpkBreakpoint>
      <BpkBreakpoint
        query={BREAKPOINTS.ABOVE_MOBILE}
        matchSSR={!isMobileForSSR}
      >
        <ThemeCardImage
          imageSrc={imageSrc}
          imageAltText={imageAltText}
          imageConfig={imageConfig}
          isMobileForSSR={isMobileForSSR}
        />
      </BpkBreakpoint>
      <div className={STYLES.ThemeCard__main}>
        <div className={STYLES.ThemeCard__header}>
          <div className={STYLES.ThemeCard__leftAligned}>
            <BpkText tagName="h3" textStyle={TEXT_STYLES.heading4}>
              {title}
            </BpkText>
            {subtitle && (
              <BpkText
                textStyle={TEXT_STYLES.caption}
                className={STYLES.ThemeCard__subtitle}
              >
                {subtitle}
              </BpkText>
            )}

            {badges && <Badges badges={badges} />}
          </div>

          {!isNeverHasPrice && (
            <div className={STYLES.ThemeCard__rightAligned}>
              {price ? (
                <BpkPrice
                  price={price}
                  size={SIZES.small}
                  leadingText={leadingText}
                  align={ALIGNS.right}
                  trailingText={trailingText}
                />
              ) : (
                /* to make sure we occupy the same vertical space */ <BpkPrice
                  price="&nbsp;"
                  size={SIZES.small}
                  leadingText={noPriceString}
                  align={ALIGNS.right}
                  trailingText="&nbsp;"
                />
              )}
            </div>
          )}
        </div>
        {textBody && (
          <BpkText
            className={STYLES.ThemeCard__textBody}
            tagName="p"
            textStyle={TEXT_STYLES.bodyDefault}
          >
            {textBody}
          </BpkText>
        )}
      </div>
    </BpkCard>
  </div>
);

export default ThemeCard;
